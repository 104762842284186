import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfigService } from '../portale/_services/config.service';
import { map } from 'rxjs/operators';
import { UserInfo } from '../portale/_models/userinfo';
import { Router } from '@angular/router';
import * as notificationConfig from '../../config/notificationConfig.json';
import { NotificationsService } from 'angular2-notifications';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class ImmobiliService {
  private storage1Observer: any;
  public storage1: any;
  baseUrl = this.configService.sanybeeUrl;
  authorizated = false;
  public token: string;
  sendCall = 0;
  backCall = 0;

  constructor(
    public http: HttpClient,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    public configService: ConfigService,
    private notificationsService: NotificationsService
  ) {
    this.storage1Observer = null;
    this.storage1 = Observable.create((observer: any) => {
      this.storage1Observer = observer;
    });
  }

  getLocale(id: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    const username = curr ? curr.usr_username : this.configService.sanybeeUser;
    return this.http
      .get(
        this.baseUrl +
          'getLocale/' +
          username +
          '/' +
          (portale ? portale.pt_id : 6) +
          '/' +
          id,
        {
          headers: this.configService.createAuthorizationHeaderForDefault(
            curr,
            curr ? curr.usr_username : this.configService.sanybeeUser
          ),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getImmobile(id: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getImmobile/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          id,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getLocaleDettaglio(id: number): any {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getLocaleDettaglio/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          id,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  registraLocale(idloc: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'registraLocale/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          idloc,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  addImmobile(imm: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .post(
        this.baseUrl + 'addImmobile/' + curr.usr_username + '/' + portale.pt_id,
        imm,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  updImmobile(imm: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .put(
        this.baseUrl + 'updImmobile/' + curr.usr_username + '/' + portale.pt_id,
        imm,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getImmobili(limitda: number, pageelement: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    if (curr) {
      return this.http
        .get(
          this.baseUrl +
            'getImmobiliPaginata/' +
            curr.usr_username +
            '/' +
            portale.pt_id +
            '?limitda=' +
            limitda +
            '&limitper=' +
            pageelement,
          {
            headers: this.configService.createAuthorizationHeader(curr),
            observe: 'response',
          }
        )
        .pipe(
          map((res: any) => {
            return this.configService.setToken(res);
          }),
          finalize(() => {
            this.dismissLoader();
          })
        );
    } else {
      this.dismissLoader();
      this.router.navigate(['']);
    }
  }

  getGestori(): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getGestori/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/',
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getImmobiliDaFiltri(
    mailgestore: string,
    mailutente: string,
    nomeimm: string,
    limitda: number,
    limitper: number
  ): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getImmobiliDaFiltri/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          mailgestore +
          '/' +
          mailutente +
          '/' +
          nomeimm +
          '/?limitda=' +
          limitda +
          '&limitper=' +
          limitper,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getRegistrazioniImmobile(immid: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getRegistrazioniImmobile/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          immid,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  delImmobile(immid: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .delete(
        this.baseUrl +
          'delImmobile/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          immid,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  delLocale(locid: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .delete(
        this.baseUrl +
          'deleteLocale/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          locid,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  addLocale(loc: any, immid: number): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .post(
        this.baseUrl +
          'addLocale/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          immid,
        loc,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  updLocale(loc: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .put(
        this.baseUrl + 'updLocale/' + curr.usr_username + '/' + portale.pt_id,
        loc,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  stampaQrCodeImmobile(modalita: number, imm: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .post(
        this.baseUrl +
          'stampaQrCodeImmobile/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          modalita +
          '/' +
          this.configService.language,
        imm,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  stampaQrCodeLocale(
    modalita: number,
    loc: any,
    auto: boolean
  ): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .post(
        this.baseUrl +
          'stampaQrCodeLocale/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          modalita +
          '/' +
          this.configService.language +
          '?auto=' +
          auto,
        loc,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getAbilitazionibyOperatore(username: string): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getAbilitazionibyOperatore/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          username +
          '/',
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getAbilitazionibyGestore(username: string): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .get(
        this.baseUrl +
          'getAbilitazionibyGestore/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          username +
          '/',
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  updAbilitazioni(username: string, immList: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .put(
        this.baseUrl +
          'updAbilitazioni/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          username +
          '/',
        immList,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }
  updAbilitazioniGestore(immList: any, usrusername: string): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .put(
        this.baseUrl +
          'updAbilitazioniGestore/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          usrusername +
          '/',
        immList,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  stampa(resin: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .post(
        this.baseUrl +
          'stampa/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          this.configService.language,
        resin,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  stampaLocale(resin: any): Observable<any> {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http
      .post(
        this.baseUrl +
          'stampaLocale/' +
          curr.usr_username +
          '/' +
          portale.pt_id +
          '/' +
          this.configService.language,
        resin,
        {
          headers: this.configService.createAuthorizationHeader(curr),
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }),
        finalize(() => {
          this.dismissLoader();
        })
      );
  }

  NotificationSuccess(msg: string, success: boolean): void {
    if (success) {
      this.notificationsService.success(
        msg,
        (notificationConfig as any).notificationOperationSuccess.content,
        (notificationConfig as any).notificationOperationSuccess.options
      );
    } else {
      this.notificationsService.error(
        msg,
        (notificationConfig as any).notificationOperationSuccess.content,
        (notificationConfig as any).notificationOperationSuccess.options
      );
    }
  }

  setStorage(user: any): void {
    this.storage1Observer.next(user);
  }
  changeStorage(): void {
    this.storage1Observer.next(null);
  }

  presentLoader(): void {
    this.sendCall++;
    this.spinnerService.show();
  }

  dismissLoader(): void {
    this.backCall++;
    if (this.backCall === this.sendCall) {
      this.spinnerService.hide();
      this.backCall = 0;
      this.sendCall = 0;
    }
  }

  controlloUser(): void {
    const json = localStorage.getItem('currentUser');
    if (json) {
      const ut = JSON.parse(json);
      if (ut && ut.usr_username === this.configService.sanybeeUser) {
        this.router.navigate(['']);
      }
    } else {
      this.router.navigate(['']);
    }
  }
}
