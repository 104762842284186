export class StaticConfig {
    static maxpageelement = 20;
    static mailmaxpageelement = 0;
    static portalUrl: string;
    static urlbaseLogin: string;
    static sanybeeUrl: string;
    static urlSitoSanybee: string;
    static adminrole: string;
    static operatorerole: string;
    static gestorerole: string;
    // tslint:disable-next-line:variable-name
    static sanybee_pt_id: number;
    static sanybeeUser: string;
    static sanybeePwd: string;
    static modalOpen: boolean;
    static limitperImmobili: number;
    static limitperUtenti: number;
    static limitperAbilitazioni: number;
    static portalID: number;

    public ckEditorConfig: {};
    constructor() {
    }
}
