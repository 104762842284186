import { Component, OnInit, AfterContentChecked, AfterViewInit, OnChanges, DoCheck, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './portale/_services/config.service';
import { Location } from '@angular/common';
import { URLSearchParams } from "@angular/http";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentChecked {
  tabIndex = 0;
  title = 'sanitysystem';
  loggato = false;
  c: any;


  constructor(private router: Router, public confService: ConfigService, private location: Location) { }

  ngOnInit() {
    let params = new URLSearchParams(window.location.search);
    let tokenQueryParam: string = params.get('?UUID');
    if(tokenQueryParam){
      this.router.navigate(['attivazione',tokenQueryParam]);
        }else{
    this.c = JSON.parse(localStorage.getItem('currentUser'));
    if (this.c && this.c.usr_primoaccesso === 1) {
      this.router.navigate(['/updpassword/' + this.c.usr_id]);
    }
  this.controllo();
}
  }

  controllo() {
    this.c = JSON.parse(localStorage.getItem('currentUser'));
    if (this.c) {
      if (this.c.usr_username !== this.confService.sanybeeUser && !location.pathname.includes('/updpassword/')) {
        this.loggato = true;
      } else
        this.loggato = false;
    } else
      this.loggato = false;
      this.riordinaTabs();
  }


  naviga(index: number) {
    localStorage.setItem("tabIndex",index.toString());
    switch (index) {
      case 0:
        this.router.navigate(['manager-immobili']);
        break;
      case 1:
        this.router.navigate(['mgr-utenti']);
        break;
      case 2:
        this.router.navigate(['mgr-lingue']);
        break;
    }
  }


  ngAfterContentChecked() {
    this.controllo();
  }


  tabClick() {
  this.naviga(this.tabIndex);
}

  riordinaTabs() {
      if(!location.pathname.includes("attivazione"))
    switch (location.pathname) {
      case "/":
        this.tabIndex = 0;
        break;
      case "/manager-immobili":
        this.tabIndex = 0;
        break;
      case "/mgr-utenti":
        this.tabIndex = 1;
        break;
      case "/mgr-lingue":
        this.tabIndex = 2;
        break;
        default:
          this.tabIndex = localStorage.getItem("tabIndex")?Number(localStorage.getItem("tabIndex").toString()):0;
        break;
    }
  }
}
