import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { PortalService } from '../portale/_services/portal.service';
import { Router } from '@angular/router';
import { UserPw } from '../portale/_models/user-pw';
import { Portale } from '../portale/_models/portale';
import { UserInfo } from '../portale/_models/userinfo';
import * as notificationConfig from '../../config/notificationConfig.json';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirm_dialog/confirm_dialog.page';
import { NotificationsService } from 'angular2-notifications';
import { ConfigService } from '../portale/_services/config.service';


@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
// tslint:disable-next-line:component-class-suffix
export class LoginPage implements OnInit {
  user: UserInfo = new UserInfo();
  constructor(
    private notificationsService: NotificationsService,
    private service: PortalService, private router: Router,
    public dialog: MatDialog,
    private configService: ConfigService
  ) {
  }
  error = '';
  model: UserPw = new UserPw();
  portale: Portale;

  ngOnInit(): void {
    this.model.username = '';
    this.model.password = '';
  }

  accesso(): void {
    this.model.portale = this.portale;
    const baseurl = window.location.hostname;
    this.model.username = this.model.username.trim();
    this.service.login(this.model, '', baseurl).then(() => {
      const c = JSON.parse(localStorage.getItem('currentUser'));
      if (c.authorities.toString().includes(this.configService.operatorerole)) {
        localStorage.clear();
        this.dialog.open(ConfirmDialog, {
          data: {
            titolo: 'Attenzione!',
            testo: 'Possono accedere al portale web solo le utenze gestore!',
            btnOk: true
          }
        });
        this.router.navigate(['/manager-immobili']);
      }
      else if (c.usr_primoaccesso === 1) {
        this.router.navigate(['/updpassword/' + c.usr_id]);
      } else {
        this.router.navigate(['/manager-immobili']);
      }

    }).catch(err => {
      this.NotificationSuccess('Attenzione! Username o Password errati', false);
    });
  }

  NotificationSuccess(msg: string, success: boolean): void {
    if (success) {
      this.notificationsService.success(
        msg,
        (notificationConfig as any).notificationOperationSuccess.content,
        (notificationConfig as any).notificationOperationSuccess.options,
      );
    } else {
      this.notificationsService.error(
        msg,
        (notificationConfig as any).notificationOperationSuccess.content,
        (notificationConfig as any).notificationOperationSuccess.options,
      );
    }
  }

}
