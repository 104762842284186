import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TestataPage } from './testata.page';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [TestataPage],
  exports: [TestataPage],
  entryComponents: [TestataPage]
})
export class TestataModule { }
