import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { HttpErrorInterceptor } from './utility/HttpErrorInterceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TestataModule } from './testata/testata.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalImmobilePageModule } from './modal_immobile/modal-immobile.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialog } from './confirm_dialog/confirm_dialog.page';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import { ConfigService } from './portale/_services/config.service';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialog
  ],
  imports: [
    BrowserModule, AppRoutingModule, NgxSpinnerModule,
    CommonModule, FormsModule, HttpClientModule, TestataModule,
    LoginModule, ModalImmobilePageModule,
    SimpleNotificationsModule.forRoot(),
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatSidenavModule,
    MatDialogModule,
    BrowserAnimationsModule],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (configService: ConfigService) => function() { return configService.load(); },
    deps: [ConfigService],
    multi: true
  },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
