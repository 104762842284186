import { Injectable, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import * as config from '../../../config/config.json';
import { throwError } from 'rxjs';
import { Subject, Observable } from 'rxjs';
import { UserInfo } from '../_models/userinfo';
import { HttpHeaders } from '@angular/common/http';
import { StaticConfig } from './static_config';


@Injectable()
export class ConfigService implements OnInit {

    public maxpageelement = 20;
    public mailmaxpageelement = 0;
    public portalUrl: string;
    public urlbaseLogin: string;
    public sanybeeUrl: string;
    public urlSitoSanybee: string;
    public adminrole: string;
    public operatorerole: string;
    public gestorerole: string;
    // tslint:disable-next-line:variable-name
    public sanybee_pt_id = 8;
    public sanybeeUser: string;
    public sanybeePwd: string;
    public modalOpen: boolean;
    public limitperImmobili: number;
    public limitperUtenti: number;
    public limitperAbilitazioni: number;
    public portalID: number;
    private config;
    public alertEvent = false;

    private numOfNotSource = new Subject<any>();
    numOfNot$ = this.numOfNotSource.asObservable();
    language = 'it';
    constructor(private http: Http) {
        this.maxpageelement = 20;
        this.mailmaxpageelement = 0;
        this.portalUrl = StaticConfig.portalUrl;
        this.sanybeeUrl = StaticConfig.sanybeeUrl;
        this.urlSitoSanybee = StaticConfig.urlSitoSanybee;
        this.adminrole = StaticConfig.adminrole;
        this.operatorerole = StaticConfig.operatorerole;
        this.gestorerole = StaticConfig.gestorerole;
        // this.sanybee_pt_id = StaticConfig.sanybee_pt_id;
        this.sanybeeUser = StaticConfig.sanybeeUser;
        this.sanybeePwd = StaticConfig.sanybeePwd;
        this.modalOpen = StaticConfig.modalOpen;
        this.limitperImmobili = StaticConfig.limitperImmobili;
        this.limitperUtenti = StaticConfig.limitperUtenti;
        this.limitperAbilitazioni = StaticConfig.limitperAbilitazioni;
        this.portalID = StaticConfig.portalID;
    }

    // tslint:disable-next-line:contextual-lifecycle
    ngOnInit(): void {
        console.log(window.location.hostname);
        this.load();
    }
    totNot(not: any): void {
        this.numOfNotSource.next(not);
    }
    getUrl(key: any, keyBaseUrl: any): any {
        console.log(key + ':' + this.config[key]);
        return this.config[key];
    }
    async load(): Promise<void> {
        console.log(window.location.hostname);
        if (window.location.hostname !== 'localhost') {

            await this.loadProduzione();
        } else {
            await this.loadSviluppo();
            // this.loadProduzione();
        }
    }
    loadProduzione(): any {
        return new Promise((resolve, reject) => {
            this.http.get('config/config.json').subscribe((responseDate: any) => {
                this.config = JSON.parse(responseDate._body);
                console.log(this.config);
                this.portalUrl = this.getUrl('portalUrl', 'url');
                StaticConfig.portalUrl = this.portalUrl;
                this.sanybeeUrl = this.getUrl('sanybeeUrl', 'url');
                StaticConfig.sanybeeUrl = this.sanybeeUrl;
                this.urlSitoSanybee = this.getUrl('urlSitoSanybee', 'url');
                StaticConfig.urlSitoSanybee = this.urlSitoSanybee;
                this.adminrole = this.getUrl('adminrole', 'url');
                StaticConfig.adminrole = this.adminrole;
                this.operatorerole = this.getUrl('operatorerole', 'url');
                StaticConfig.operatorerole = this.operatorerole;
                this.gestorerole = this.getUrl('gestorerole', 'url');
                StaticConfig.gestorerole = this.gestorerole;
                this.sanybeeUser = this.getUrl('sanybeeUser', 'url');
                StaticConfig.sanybeeUser = this.sanybeeUser;
                this.sanybeePwd = this.getUrl('sanybeePwd', 'url');
                StaticConfig.sanybeePwd = this.sanybeePwd;
                if (this.getUrl('pormodalOpentalUrl', 'url') === 'true') {
                    this.modalOpen = true;
                } else {
                    this.modalOpen = false;
                }
                StaticConfig.modalOpen = this.modalOpen;
                this.limitperImmobili = Number(this.getUrl('limitperImmobili', 'url'));
                StaticConfig.limitperImmobili = this.limitperImmobili;
                this.limitperUtenti = Number(this.getUrl('limitperUtenti', 'url'));
                StaticConfig.limitperUtenti = this.limitperUtenti;
                this.limitperAbilitazioni = Number(this.getUrl('limitperAbilitazioni', 'url'));
                StaticConfig.limitperAbilitazioni = this.limitperAbilitazioni;
                this.portalID = Number(this.getUrl('portalID', 'url'));
                StaticConfig.portalID = this.portalID;

                resolve(true);
            });
        });

    }
    loadSviluppo(): void {
        let currentUser: UserInfo;
        if (localStorage.getItem('currentUser') != null) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        let urlconfig = '';
        const hostname = window.location.hostname;
        const baseurl: any[] = (config as any).baseurl;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < baseurl.length; i++) {
            if (baseurl[i].name === hostname) {
                urlconfig = baseurl[i].url;
                this.urlbaseLogin = baseurl[i].urlbaseLogin;
                StaticConfig.urlbaseLogin = this.urlbaseLogin;
                break;
            }
        }
        this.portalUrl = (config as any).portalUrl;
        StaticConfig.portalUrl = this.portalUrl;
        this.sanybeeUrl = (config as any).sanybeeUrl;
        StaticConfig.sanybeeUrl = this.sanybeeUrl;
        this.urlSitoSanybee = (config as any).urlSitoSanybee;
        StaticConfig.urlSitoSanybee = this.urlSitoSanybee;
        this.adminrole = (config as any).adminrole;
        StaticConfig.adminrole = this.adminrole;
        this.operatorerole = (config as any).operatorerole;
        StaticConfig.operatorerole = this.operatorerole;
        this.gestorerole = (config as any).gestorerole;
        StaticConfig.gestorerole = this.gestorerole;
        this.sanybeeUser = (config as any).sanybeeUser;
        StaticConfig.sanybeeUser = this.sanybeeUser;
        this.sanybeePwd = (config as any).sanybeePwd;
        StaticConfig.sanybeePwd = this.sanybeePwd;
        if ((config as any).pormodalOpentalUrl === 'true') {
            this.modalOpen = true;
        } else {
            this.modalOpen = false;
        }
        StaticConfig.modalOpen = this.modalOpen;
        this.limitperImmobili = (config as any).limitperImmobili;
        StaticConfig.limitperImmobili = this.limitperImmobili;
        this.limitperUtenti = (config as any).limitperUtenti;
        StaticConfig.limitperUtenti = this.limitperUtenti;
        this.limitperAbilitazioni = (config as any).limitperAbilitazioni;
        StaticConfig.limitperAbilitazioni = this.limitperAbilitazioni;
        this.portalID = (config as any).portalID;
        StaticConfig.portalID = this.portalID;
    }



    public createAuthorizationHeader(currentUser?: UserInfo): HttpHeaders {

        let headers = new HttpHeaders();
        if (currentUser == null) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        if (currentUser != null) {
            headers = headers.set('Access-Control-Allow-Credentials', 'true');
            headers = headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type,Authorization, Accept');
            headers = headers.set('Access-Control-Allow-Methods', 'POST,OPTIONS,GET,PUT,DELETE');
            headers = headers.set('Access-Control-Allow-Origin', '*');
            headers = headers.set('rejectUnauthorized', 'false');
            // headers = headers.set('Accept',
            // 'application/json;application/octet-stream;application/pdf;application/x-www-form-urlencoded');
            headers = headers.set('Accept', '*/*');
            headers = headers.set('Authorization', 'Bearer ' + currentUser.token);
        } else {
            console.log('Utente non recuperato');
        }
        return headers;
    }

    // tslint:disable-next-line:variable-name
    public createAuthorizationHeaderForDefault(currentUser: UserInfo, default_user: string): HttpHeaders {
        let headers = new HttpHeaders();
        if (currentUser == null) {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        if (currentUser != null) {
            headers = headers.set('Access-Control-Allow-Credentials', 'true');
            headers = headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type,Authorization, Accept');
            headers = headers.set('Access-Control-Allow-Methods', 'POST,OPTIONS,GET,PUT,DELETE');
            headers = headers.set('Access-Control-Allow-Origin', '*');
            headers = headers.set('rejectUnauthorized', 'false');
            // headers = headers.set('Accept',
            // 'application/json;application/octet-stream;application/pdf;application/x-www-form-urlencoded');
            headers = headers.set('Accept', '*/*');
            if (currentUser.usr_username !== default_user) {
                headers = headers.set('Authorization', 'Bearer ' + currentUser.token);
            }
        } else {
            console.log('Utente non recuperato');
        }
        return headers;
    }



    public extractData(res: Response): any {
        // return res.json();
        return res;
    }

    public handleErrorObservable(error: Response | any): any {
        // return Observable.throw(error);
        return throwError(error);
    }

    public handleErrorPromise(error: Response | any): any {
        return Promise.reject(error.message || error);

    }




    parseDate(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
            const str = value.split('-');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    setToken(res: any): any {
        if (res) {

            const header: HttpHeaders = res.headers;
            const token = header.get('token');
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser && token) {
                currentUser.token = token;
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
        }
        return res.body;
    }

}
